p {
    margin-top: 20px;
}

.body {
    margin: 10px 20px;
}

form {
    width: 100%;
}

.question-text {
    font-weight: bold;
    font-size: 18px;
}

textarea {
    resize: none;
}

.inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    // * {
    //     margin-bottom: 20px;
    // }
}

.questions {
    margin: 30px auto;
    width: 100%;
    max-width: 500px;
}
